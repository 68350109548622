.landing-page {
    padding-top: 70px;
    background-color: #ffffff;

    .boxshadow {
        box-shadow: 0px 2px 5px #7c44443b;
    }

    .btn-cta {
        font-size: 1.35rem;
        padding: 0.5rem 1rem;
        max-width: 100%;
        color: #ffffff;
    }

    &__banner {
        background-color: #ffffff;
        .content {
            padding: 0;
        }
        .link-whatsapp {
            cursor: pointer;
        }
    }
    &__section {
        background-color: #fbfbfb;
        .section__title {
            padding: 2rem;
            font-weight: 700;
        }
    }

    .navbar {
        &.lp-header {
            .navbar-brand {
                img {
                    max-height: 50px;
                }
            }
            &.nav-fixed {
                height: 70px;
                position: fixed;
                top: 0;
            }
        }
    }

    .bloco {
        position: relative;
        &__title {
            text-align: center;
            font-weight: 700;
            margin-bottom: 2rem;
            margin-top: 2rem;
        }

        &__conteudo {
            .bloco__itens {
                align-items: center;
                &--right {
                    .bloco__texto {
                        padding: 2rem 0 2rem 3rem;
                    }
                    .bloco__image {
                        float: left;
                        margin: 2rem 0;
                    }
                }
                &--left {
                    .bloco__texto {
                        padding: 2rem 3rem 2rem 0;
                    }
                    .bloco__image {
                        float: right;
                        margin: 2rem 0;
                    }
                }
            }
            .bloco__title {
                padding-top: 3rem;
            }
        }

        &__atributos {
            padding: 2rem 0 0.5rem 0;
            .item {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;

                margin-bottom: 1rem;

                .image {
                    width: 60px;
                    margin-right: 1rem;
                    img {
                        max-width: 60px;
                    }
                }
            }
        }
        &__produtos {
            padding: 2rem 0 4rem 0;
            .card {
                flex-direction: row;
                padding: 1rem;
            }

            .product__name {
                height: auto;
                padding-top: 1rem;
            }
            .product__name--link {
                font-size: 1.5rem;
                font-weight: 400;
            }
            .product__price {
                font-size: 1.125rem;
                color: #333333;
            }
            .product__content {
                width: 100%;
            }
            .product__buttons {
                display: flex;
                position: relative;
                left: 0;
                width: 40%;
                margin-top: 2rem;
            }
            .product__photo {
                width: 100%;
                max-width: 400px;
                &--link {
                    height: 300px;
                }
                .product__image-placeholder {
                    svg {
                        width: 50%;
                    }
                }
            }

            .product__photo {
                max-width: 200px;
                &--link {
                    height: 270px;
                }
            }

            .product__content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
            }
            .product__buttons {
                width: auto;
                .btn {
                    padding: 0.65rem 1.25rem;
                }
            }
            .card-product-1 {
                .product__photo--link {
                    height: 300px;
                }
            }
        }
        &__faq {
            padding: 2rem 0 4rem 0;
        }
        &__imagem {
            text-align: center;
        }
    }

    .container-fluid {
        .bloco {
            &__conteudo {
                .bloco__itens {
                    align-items: center;
                    &--right {
                        .bloco__texto {
                            padding-right: 17rem;
                            padding-left: 3rem;
                        }
                    }
                    &--left {
                        .bloco__texto {
                            padding-left: 17rem;
                            padding-right: 3rem;
                        }
                    }
                    .bloco__image {
                        float: none;
                        margin: 0;
                        img {
                            width: 100%;
                        }
                    }
                }
                .bloco__title {
                    padding-top: 3rem;
                }
            }
        }
    }

    // PAINEL - Preview
    .lp-section {
        position: relative;

        .product {
            background-color: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.125);
            box-shadow: none;
        }

        .bloco__conteudo {
            .bloco__itens--left {
                .bloco__texto {
                    padding-left: 3rem !important;
                }
            }
            .bloco__itens--right {
                .bloco__texto {
                    padding-right: 3rem !important;
                }
            }
        }

        .bloco__html {
            min-height: 140px;
        }

        &__block {
            min-height: 86px;
        }

        &:hover {
            .section-buttons {
                display: block;
            }
            .block-buttons {
                display: flex;
                align-items: center;
            }
        }
    }
    .section-buttons {
        position: absolute;
        right: 5px;
        top: 5px;
        color: #333333;
        background-color: rgba(255, 255, 255, 0.842);
        border-radius: 4px;
        padding: 0.5rem;
        z-index: 2;
        display: none;
    }
    .block-buttons {
        @extend .section-buttons;
        float: right;
        bottom: 15px;
        top: auto;
        z-index: 1;
    }
    .banner-mobile {
        display: none;
    }
    .banner-desktop {
        display: block;
    }

    .logo {
        img {
            max-width: 100px;
        }
    }
}
@media (max-width: 1200px) {
    .landing-page {
        .container-fluid {
            .bloco__conteudo {
                .bloco__itens--right {
                    .bloco__texto {
                        padding-right: 3rem;
                    }
                }
                .bloco__itens--left {
                    .bloco__texto {
                        padding-left: 3rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    .landing-page {
        .banner-mobile {
            display: block;
        }
        .banner-desktop {
            display: none;
        }
    }
}
@media (max-width: 575.98px) {
    .landing-page {
        .bloco__conteudo {
            .bloco__itens--right,
            .bloco__itens--left {
                .bloco__texto {
                    padding: 1rem 1.5rem;
                }
                .bloco__image {
                    padding: 1.5rem 1rem;
                }
            }
        }
        .container-fluid {
            .bloco__conteudo {
                .bloco__itens--right,
                .bloco__itens--left {
                    .bloco__image {
                        padding: 0;
                    }
                }
            }
        }

        .bloco__atributos {
            padding: 1rem 1.5rem;
        }
        .bloco__produtos {
            .card {
                margin-bottom: 0.5rem;
            }
            .product__photo--link {
                height: 200px;
            }
            .product__image-placeholder {
                height: 100%;
            }
        }

        .section-buttons {
            display: block;
        }
        .logo {
            img {
                max-width: 60px;
            }
        }
    }
}
